import React,{ useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import SimpleTable from "../../partials/SimpleTable";
import { post } from "../../services/base.service";



const ScaleManager = () => {

    const [list, setList] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        reload();
    }, []);

    const reload = () => {
        setLoading(true);
        post('/api/scale/getAll',{}).then(res =>{
            setList(res.data);
        }).finally(() => setLoading(false));
    }

    const columns = [
        { title: 'Code', field: '', width: 50 },
        { title: 'Name', field: '', width: 50 },
    ]


    return (
        <>
            <div></div>
                <SimpleTable
                    data={list}
                    columns={columns}
                />

        </>
    )
}

export default ScaleManager;