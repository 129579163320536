import React, { useState, useRef, useEffect } from "react"
import { Box, Icon, IconButton, LinearProgress, makeStyles, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Button,Col, Row } from "react-bootstrap";
import { post } from "../../services/base.service";
import { getDateTimeFormated } from "../../utils/helpers";
import Fieldset from "../../partials/Fieldset";
import SimpleTable from "../../partials/SimpleTable";
import { snackbarActions } from "../../store/ducks/snackbar.duck";


const MessageCrud = (props) => {

    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [message, setMessage] = useState("");


    const dispatch = useDispatch();



    useEffect(() => {
        reload();
    }, [])

    const reload = () => {
        post('/api/Message/List', {}).then(res => {
            setList(res.data);
        });
    }


    const addNewHandler = () => {


        if (message && message.length > 0) { //which means data is complexer than grid data and needs to be fetch
            setLoading(true);
            post('/api/Message/AddOrUpdate', { body: message }).then(res => {
                dispatch(snackbarActions.success('Message added.'));
                setMessage("");
                reload();
            }).finally(() => setLoading(false));
        } else {
            dispatch(snackbarActions.error('Message can not be empty!'));
        }
    }

    const deleteHandler = (id) => {
        if (window.confirm(`Are you sure to delete ?`)) {
            setLoading(true);
            post('/api/Message/Delete', { id: id }).then(res => {
                if (res.succeed) {
                    dispatch(snackbarActions.success('Message deleted.'));
                    reload();
                } else {
                    dispatch(snackbarActions.error(res.errorMessage));
                }
            }).finally(() => setLoading(false));
        }


    }



    let columns = [
        { title: 'Message', field: 'body', width: 700 },
        { title: 'Create Date',template: ({dateInserted}) => getDateTimeFormated(dateInserted), width: 120 },
        {
            title: '', width: 100, template: (item) => <>
                <IconButton title='Delete' onClick={() => deleteHandler(item.id)}><Icon className="fa fa-trash" /></IconButton>
            </>
        },
    ]

    return (
        <>

            <Fieldset title="Messages">
                {loading && <LinearProgress />}
                <Box p={1}>
                    <Row>
                        <Col sm={10}>
                            <TextField
                                variant='outlined'
                                label='Message'
                                multiline
                                rows={2}
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                                fullWidth
                            />
                        </Col>
                        <Col sm={2}>
                            <Button variant="primary" disabled={loading} onClick={addNewHandler} >Add</Button>
                        </Col>
                    </Row>
                </Box>
                <SimpleTable
                    data={list}
                    columns={columns}
                />
            </Fieldset>
        </>
    );
}


export default MessageCrud;