import React from 'react'


const Fieldset = ({ title, children, ...rest }) => {

    return (
        <div className='fieldset' {...rest}>
            {title && <div className='fieldset-title'>{title}</div>}
            <div className='fieldset-body'>{children}</div>
        </div>
    )

}
export default Fieldset;