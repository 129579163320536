import { Table } from 'react-bootstrap';
import React, { memo } from 'react'
import objectPath from 'object-path';

const SimpleTable = memo((props) => {
    const {
        columns,        // mandatory
        data,           // mandatory
        onRowClick,
        rowStyle, // 
        style,
        className = ""

    } = props;


    const clickHandler = (item) => {
        if (onRowClick) {
            onRowClick(item);
        }
    }


    return (<>
        <Table size='sm' className={"table-striped simple-table " + className+ (onRowClick ? " st-clickable":"")} style={{ ...style }}>
            <thead>
                <tr>
                    {columns.filter(x => !x.hidden).map((col, i) =>
                        <th
                            key={i}
                            align={col.align ? col.align : "inherit"}
                            style={col.width ? { width: col.width } : {}}
                        >
                            {col.title}

                        </th>
                    )}
                </tr>
            </thead>
            <tbody>
                {data && data.map((row, k) => {

                    let style = rowStyle ? {...rowStyle(row)} :{};
                    return (
                        <tr key={k} onClick={() => clickHandler(row)} style={style}>
                            {columns.filter(x => !x.hidden).map((col, j) => {
                                var cell = null;
                                if (col.field == "#") {
                                    cell = <>{k+1}</>;
                                }else if (col.template) {
                                    cell = col.template(row);
                                } else {
                                    //cell = row[col.field];  //simple  e.g. title
                                    cell = objectPath.get(row, col.field);//   complex e.g. type.title
                                }
                                return <td key={j}>{cell}</td>
                            })}
                        </tr>);
                }
                )}
            </tbody>
        </Table>
    </>)

})

export default SimpleTable;