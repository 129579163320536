import { Box, Container, Divider } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, Redirect, Route, Switch } from "react-router-dom";
//import Registration from "./Registration";
//import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
//import { Helmet } from "react-helmet";

export default function AuthPage() {
    return (
        <>
            <Container maxWidth="md" >
                <div className='auth-container'>
                    <div className='auth-content'>
                        <Row >
                            <Col sm >
                                <div className='auth-desc'>
                                    <Box>
                                        <div style={{
                                            height: '140px',
                                            backgroundImage: 'url(/media/logo.jpg)',
                                            backgroundSize: '100%',
                                            backgroundRepeat: 'no-repeat'
                                        }}></div>
                                    </Box>

                                    <Divider />
                                    <Box pt={2}>

                                        <h3
                                            style={{
                                                color: '#fff'
                                            }}
                                        >Maritime Paper Products Ltd</h3>
                                    </Box>

                                </div>
                            </Col>
                            <Col sm >
                                <div className='auth-form'>

                                    <Switch>

                                        <Route path="/auth/login" component={Login} />

                                        <Redirect from="/auth" exact={true} to="/auth/login" />
                                        <Redirect to="/auth/login" />
                                        {/* <Route path="/auth/forgot-password" component={ForgotPassword} */}
                                    </Switch>
                                </div>
                            </Col>
                        </Row>
                        
                    </div>

                   
                </div>
                <div className="header-logo">
                    <div class="header-logo-right">Powered By</div>
                    <div>
                        <a href="https://hanatech.ca/">
                        <img src={process.env.PUBLIC_URL + '/logo.svg'} alt="logo" className="header-logo-img" ></img>
                        </a>
                    </div>
                    <div class="header-logo-left">IOT</div>
                </div>
            </Container>

        </>
    );
}
