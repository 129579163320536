import { Box, Paper, Button, IconButton, Icon } from '@material-ui/core';
import { blue, red } from '@material-ui/core/colors';
import React from 'react'
import { Link, useLocation } from 'react-router-dom';


const Header = () => {

    return (
        <>
            <div className='page-header'>
                <div className="header-title">
                    <div className="header-logo">&nbsp;</div>
                    <div>Maritime Paper Products Ltd</div>
                </div>

                <div>
                    <div className="inline-header">
                       
                        <div>
                            <a href="https://hanatech.ca/">
                                <img src={process.env.PUBLIC_URL + '/logo.svg'} alt="logo" className="header-logo-img" ></img>
                            </a>
                        </div>
                        <div class="header-logo-right">Powered By Hanatech IOT</div>
                        
                    </div>
                    <Link to={'/logout'} title='log out'>
                        <IconButton style={{backgroundColor: red[50]}}>
                            <Icon className='fa fa-sign-out-alt' />
                        </IconButton>
                    </Link>
                </div>
            </div>
            <Navbar />
        </>
    )

}
export default Header;

const Navbar = () => {

    return (
        <Paper className='navbar' elevation={2} >
            <Box>
                <NavbarLink title="Dashboard" to='/Dashboard' />
                <NavbarLink title="Manual Adjustment" to='/Adjustment' />
                <NavbarLink title="Auto Adjustment" to='/AutoAdjustment' />
                <NavbarLink title="Adjustment Report" to='/AdjustmentReport' />
                <NavbarLink title="Daily Report" to='/WeightLog' />
                <NavbarLink title="Scale Log" to='/LogChart' />
                <NavbarLink title="Activity Log" to='/ActivityLog' />


            </Box>
            <Box>
                <NavbarLink title="User Setting" to='/UserSetting' />
                <NavbarLink title="Messaging" to='/Messaging' />
            </Box>
        </Paper>
    )
}

const NavbarLink = ({ title, to }) => {
    const location = useLocation();
    console.log(location.pathname);
    var selected = location.pathname.toLocaleLowerCase() == to.toLocaleLowerCase();
    return (
        <Link to={to}>
            <Button className={'nav-link ' + (selected ? 'nav-link-selected' : '')}  >{title}</Button>
        </Link>
    )
}