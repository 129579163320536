import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  expireDate: undefined,

};

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ["user", "authToken", "expireDate"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        return {
          authToken: action.payload.token,
          user: action.payload.user,
          expireDate: new Date(action.payload.expireDate).getTime()
        };
      }


      case actionTypes.Logout: {
        return initialAuthState;
      }


      default:
        return state;
    }
  }
);

export const actions = {
  login: loginData => ({ type: actionTypes.Login, payload: loginData }),
  logout: () => ({ type: actionTypes.Logout }),
};


