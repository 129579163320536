import { Box, Checkbox, FormControl, FormControlLabel, Icon, IconButton, InputLabel, LinearProgress, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Col, Button as BButton, Row, Alert,Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Fieldset from '../../partials/Fieldset';
import SimpleTable from '../../partials/SimpleTable';
import { post } from '../../services/base.service';
import { loaderActions } from '../../store/ducks/loader.duck';
import { snackbarActions } from '../../store/ducks/snackbar.duck';


const UserSetting = () => {

    const [loading, setLoading] = useState([]);
    const [list, setList] = useState([]);
    const [itemToEdit, setItemToEdit] = useState();
    const [showChangePassFor, setShowChangePassFor] = useState();
    const [filter, setFilter] = useState({
        startIndex: 1,
        count: 100
    });

    const reload = () => setFilter(prev => ({ ...prev }))

    useEffect(() => {
        setLoading(true);
        post('/api/UserManagement/GetList', filter).then(res => {
            setList(res.data.resources);
        }).finally(() => setLoading(false));
    }, [filter]);

    const deleteUser = (id, name) => {
        if (window.confirm(`Are you sure to delete '${name}'?`)) {
            setLoading(true);
            post('/api/UserManagement/DeleteUser', { id: id }).then(res => {
                if (res.succeed) {
                    //setMessage({ success:  `'${name} Deleted!` })
                    reload();
                } else {
                    alert(res.errorMessage)
                }
            }).finally(() => setLoading(false));
        }
    };

    const editUserHandler = (item) => {
        setItemToEdit(item);

    }

    const columns = [
        { title: 'First Name', field: 'name.givenName', width: 100 },
        { title: 'Last Name', field: 'name.familyName', width: 100 },
        { title: 'User Name', field: 'userName', width: 100 },
        { title: 'Email', width: 100, template: ({ emails }) => emails && emails.length > 0 ? emails[0] : null },
        { title: 'Mobile', field: 'nickName', width: 100 },
        { title: 'User Role', field: 'groupsStr', width: 100 },
        { title: 'Notification', field: 'title', width: 100 },
        {
            title: '', width: 100, template: (item) => <>
                <IconButton title='Edit' onClick={() => editUserHandler(item)}><Icon className="fa fa-pen" /></IconButton>&nbsp;
                <IconButton title='Change Password' onClick={() => setShowChangePassFor(item.userName)}><Icon className="fa fa-key" /></IconButton>&nbsp;
                <IconButton title='Delete' onClick={() => deleteUser(item.id, item.userName)}><Icon className="fa fa-trash" /></IconButton>
            </>
        },
        // {title:'Notification', field:'', width: 100},
    ]


    return (
        <>

            <UserForm onSucceed={reload} itemToEdit={itemToEdit} />

            <Fieldset title="User List">
                {loading && <LinearProgress />}
                <SimpleTable
                    data={list}
                    columns={columns}
                />
            </Fieldset>

            {showChangePassFor &&
            <ChangePassModal username={showChangePassFor} onDismiss={() => setShowChangePassFor(null)} />
        }
        </>
    )



}


const UserForm = ({ onSucceed, itemToEdit }) => {
    const defaultValues = { firstName: '', lastName: '', userName: '', password: '', email: '', mobile: '', groupId: '', smsNotify: false, emailNotify: false }
    const [loading, setLoading] = useState();
    const [message, setMessage] = useState();
    const [groups, setGroups] = useState(null);
    const [notifTypes, setNotifTypes] = useState(null);
    const [editMode, setEditMode] = useState();
    const { control, errors, handleSubmit, watch, reset } = useForm({defaultValues:defaultValues});

    useEffect(() => {
        post('/api/UserManagement/GetAllGroups', {}).then(res => {
            setGroups(res.data);

        });

        // post('/api/UserManagement/GetAllNotifTypes', {}).then(res => {
        //     setNotifTypes(res.data);
        // });

    }, []);

    useEffect(() => {
        if (itemToEdit) {
            setEditMode(true);
            const user = {
                id: itemToEdit.id,
                firstName: itemToEdit.name.givenName || '',
                lastName: itemToEdit.name.familyName || '',
                userName: itemToEdit.userName || '',
                email: itemToEdit.emails && itemToEdit.emails.length > 0 ? itemToEdit.emails[0] : '',
                mobile: itemToEdit.nickName || '',
                groupId: itemToEdit.groups && itemToEdit.groups.length > 0 ? itemToEdit.groups[0].groupId : '',
                smsNotify: itemToEdit.title ? itemToEdit.title.indexOf('SMS') > -1 : false,
                emailNotify: itemToEdit.title ? itemToEdit.title.indexOf('Email') > -1: false,
            }
            reset(user, { keepDirty: true, keepTouched: true });
        } else {
            setEditMode(false);
        }
    }, [itemToEdit])


    const cancelHandler = () => {
        reset({ ...defaultValues });
        setEditMode(false);
    }



    const submitHandler = (data) => {
        var u = { ...data };
        setMessage(null);
        setLoading(true);
        post('/api/UserManagement/AddOrUpdate', u).then(res => {
            if (res.succeed) {
                setMessage({ success: editMode ? 'Updated successfully!' : 'Added successfully!' })
                onSucceed();
                setEditMode(false);
                reset(defaultValues);
            } else {
                setMessage({ error: res.errorMessage })
            }
        }).finally(() => setLoading(false));

    }


    return (<>
        <Fieldset title={editMode ? "Edit User" : "Add New User"}>
            {message &&
                <Box p={1}>
                    <Alert variant={message.error ? "danger" : "success"} onClose={() => setMessage(null)} dismissible>
                        {message.error}
                        {message.success}
                    </Alert>
                </Box>
            }
            <form onSubmit={handleSubmit(submitHandler)} className="form-container">
                {editMode &&
                    <Controller
                        name="id"
                        control={control}
                        as={<input type='hidden' />}
                        defaultValue=''
                    />
                }
                <Row>
                    <Col sm={6}>
                        <Controller
                            name="firstName"
                            control={control}
                            rules={{ required: 'required' }}
                            as={
                                <TextField
                                    variant="outlined"
                                    label="First Name"
                                    size="small"
                                    error={Boolean(errors && errors.firstName)}
                                    fullWidth
                                />}
                            defaultValue=''
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            name="lastName"
                            control={control}
                            rules={{ required: 'required' }}
                            as={
                                <TextField
                                    variant="outlined"
                                    label="Last Name"
                                    size="small"
                                    error={Boolean(errors && errors.lastName)}
                                    fullWidth
                                />}
                            defaultValue=''
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            name="email"
                            control={control}
                            rules={{ required: 'required' }}
                            as={
                                <TextField
                                    variant="outlined"
                                    label="Email"
                                    size="small"
                                    error={Boolean(errors && errors.email)}
                                    fullWidth
                                />}
                            defaultValue=''
                        />

                    </Col>
                    <Col sm={6}>
                        <Controller
                            name="mobile"
                            control={control}
                            rules={{ required: 'required' }}
                            as={
                                <TextField
                                    variant="outlined"
                                    label="Mobile No"
                                    size="small"
                                    error={Boolean(errors && errors.mobile)}
                                    fullWidth
                                />}
                            defaultValue=''
                        />
                    </Col>
                    <Col sm={6}>
                        <Controller
                            name="userName"
                            control={control}
                            rules={{ required: 'required' }}
                            as={
                                <TextField
                                    variant="outlined"
                                    label="UserName"
                                    size="small"
                                    error={Boolean(errors && errors.userName)}
                                    fullWidth
                                />}
                            defaultValue=''
                        />
                    </Col>
                    <Col sm={6}>
                        {!editMode &&
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: 'required' }}
                                as={
                                    <TextField

                                        variant="outlined"
                                        label="Password"
                                        size="small"
                                        error={Boolean(errors && errors.password)}
                                        fullWidth
                                    />}
                                defaultValue=''
                            />
                        }
                    </Col>
                    <Col sm={6}>
                        <FormControl variant="outlined" size="small" fullWidth error={Boolean(errors && errors.groupId)}>
                            <InputLabel >User Role</InputLabel>
                            <Controller
                                name="groupId"
                                control={control}
                                rules={{ required: 'required' }}
                                as={
                                    <Select
                                        label="Scale"
                                    >
                                        {groups == null && <MenuItem>loading ...</MenuItem>}
                                        {groups && groups.map(s => <MenuItem key={s.id} value={s.id}>{s.displayName}</MenuItem>)}
                                    </Select>
                                }
                                defaultValue=''
                            />
                        </FormControl>

                    </Col>
                    <Col sm={6}>
                        <FormControlLabel
                            control={
                                <Controller
                                    name="smsNotify"
                                    control={control}
                                    defaultValue={false}
                                    render={(props) => (
                                        <Checkbox
                                            {...props}
                                            checked={props.value}
                                            onChange={(e) => props.onChange(e.target.checked)}
                                        />
                                    )}
                                />
                            }
                            label="SMS Notify"
                        />
                    &nbsp;
                    <FormControlLabel
                            control={
                                <Controller
                                    name="emailNotify"
                                    control={control}
                                    defaultValue={false}
                                    render={(props) => (
                                        <Checkbox
                                            {...props}
                                            checked={props.value}
                                            onChange={(e) => props.onChange(e.target.checked)}
                                        />
                                    )}
                                />
                            }
                            label="Email Notify"
                        />
                    </Col>

                </Row>
                {loading && <LinearProgress />}
                <Row className='mt-4'>
                    <Col sm={6}>
                        <BButton variant='primary' type='submit' disabled={loading}><i className='fa fa-save' />&nbsp; Save</BButton>
                        &nbsp;
                        {editMode &&
                            <BButton variant='secondary' onClick={cancelHandler} disabled={loading}> Cancel</BButton>
                        }
                    </Col>
                </Row>
            </form>
        </Fieldset>

    </>)
}

const ChangePassModal = ({ username, onDismiss }) => {

    const [pass, setPass] = useState('');
    const dispatch = useDispatch()


    const confirmHandler = () => {
        if (!pass || pass.length < 6) {
            dispatch(snackbarActions.warning('password must be at least 6 characters.'));
            return;
        }
        dispatch(loaderActions.show());
        const data = { username, newPassword: pass };
        post('/api/UserManagement/ChangePass', data).then(res => {
            if (res.succeed) {
                dispatch(snackbarActions.success('Password changed!'));
                onDismiss();
            } else {
                dispatch(snackbarActions.error(res.errorMessage));
            }
        }).finally(() => dispatch(loaderActions.hide()));
    }

    return (

        <Modal show={true} onHide={onDismiss}>
            <Modal.Header onDismiss>
                <Modal.Title>Change password for {username}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <TextField
                            autoFocus
                            type="text"
                            label="New Password"
                            margin="normal"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={pass}
                            onChange={e => setPass(e.target.value)}
                        />
                    </Col>

                </Row>


            </Modal.Body>
            <Modal.Footer>
                <BButton variant="secondary" onClick={onDismiss}>
                    Close
          </BButton>
                <BButton variant="primary" onClick={confirmHandler}>
                    Save Changes
          </BButton>
            </Modal.Footer>
        </Modal>
    )

}

export default UserSetting;