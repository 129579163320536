import { Box, FormControl, Icon, IconButton, InputLabel, LinearProgress, MenuItem, Select, TextField } from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import React, { useEffect, useState } from 'react'
import { Modal, Col, Row, Button as BButton } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import ConfirmService from '../../layout/components/ConfirmService'
//import { updateReturn } from 'typescript'
import Fieldset from '../../partials/Fieldset'
import SimpleTable from '../../partials/SimpleTable'
import { post } from '../../services/base.service'
import { loaderActions } from '../../store/ducks/loader.duck'
import { snackbarActions } from '../../store/ducks/snackbar.duck'
import { dateFromNow, getDatePart, getDateTimeFormated } from '../../utils/helpers'


const AdjustmentReport = () => {
    const [filter, setFilter] = useState({
        scaleId: null,
        today: false,
        from: dateFromNow(-5),
        to: null
    })
    const dispatch = useDispatch()
    const [list, setList] = useState([])
    const [showEdit, setShowEdit] = useState(null)
    const [loading, setLoading] = useState()

    const columns = [
        { title: 'Row', field: '#', width: 20 },
        { title: 'Scale', template: (item) => `${item.baleWeight.scale.name} (${item.baleWeight.scale.code})`, width: 100 },
        { title: 'Change Date', template: ({ dateTime }) => getDateTimeFormated(dateTime), width: 120 },
        { title: 'Bale Date', template: ({ baleWeight }) => getDateTimeFormated(baleWeight.dateTime), width: 120 },
        { title: 'Action', field: 'action', width: 50 },
        { title: 'Bale Change', field: 'baleChange', width: 50 },
        { title: 'Weight Change', field: 'weightChange', width: 80 },
        { title: 'User', field: 'userName', width: 80 },
    ]

    

    useEffect(() => {
        

        setLoading(true);
        post('/api/adjustment/GetList', filter).then(res => {
            setList(res.data)
        }).finally(() => setLoading(false));

    }, [filter])

    return (
        <>
            <Fieldset title="Adjustment Report">
                <Box p={1}>
                    <AdjustmentFilter filter={filter} onChange={setFilter} />
                </Box>
                {loading && <LinearProgress />}
                <SimpleTable
                    data={list}
                    columns={columns}
                />
            </Fieldset>
            
        </>
    )

}

export default AdjustmentReport;

const AdjustmentFilter = ({ filter, onChange }) => {
    const [loading, setLoading] = useState();

    const { control, errors, handleSubmit } = useForm({ defaultValues: { ...filter } });
    

    const submitHandler = (data) => {
        onChange({ ...data })
    }


    return (<>

        <form onSubmit={handleSubmit(submitHandler)}>

            <Row>
                
                <Col>
                    <Controller
                        name="from"
                        control={control}
                        rules={{ required: 'required' }}
                        as={
                            <DateTimePicker
                                //onChange={(val) => setLocalFilter(prev => ({ ...prev, arrivalDateFrom: val }))}
                                //value={localFilter.arrivalDateFrom}
                                inputVariant="outlined"
                                autoOk
                                label="From Date Time"
                                size="small"
                                fullWidth
                            />}
                    />
                </Col>
                <Col>
                    <Controller
                        name="to"
                        control={control}
                        as={
                            <DateTimePicker
                                //onChange={(val) => setLocalFilter(prev => ({ ...prev, arrivalDateFrom: val }))}
                                //value={localFilter.arrivalDateFrom}
                                inputVariant="outlined"
                                autoOk
                                label="To Date Time"
                                size="small"
                                fullWidth
                            />}
                    />
                </Col>
                <Col sm={2}>
                    <BButton variant='primary' type='submit'><i className='fa fa-search' />&nbsp; Filter</BButton>
                </Col>
            </Row>
        </form>

    </>)
}

