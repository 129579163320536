import { format } from "date-fns";
import store from "../store/store";
Date.prototype.toISOString = function () {
    return format(this, "yyyy-MM-dd") + "T" + format(this, "HH:mm:ss");
};
const _baseUrllocal = '';//'http://maritimepaper.onesmartview.com';'/';
const _baseUrlserver = '';

export const baseUrl = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? // dev code
    _baseUrllocal : _baseUrlserver;

export function post(path, data) {
    path = baseUrl + path;
    var headers= {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
    };
    const { auth: { authToken } } = store.getState();

    if (authToken) {
        headers.Authorization = `Bearer ${authToken}`;
    }
    return fetch(path, {
        method: 'post',
        headers: headers,
        body: JSON.stringify(data)
    }).then(res => res.json())
        .catch(handleError)
}


const handleError = (error) => {
    console.log(error);
    if (error.response) {
        console.log(error.response.status);

        switch (error.response.status) {
            case 401:
                break;
            case 403:
                break;
            case 404:
                break;
            default:
                //this.redirectTo(document, '/500')
                break;
        }
    }

    return Promise.reject(error.response || error.message);
}