

import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Adjustment from "./adjustment/Adjustment";
import AdjustmentReport from "./adjustment/AdjustmentReport";
import AutoAdjustment from "./adjustment/AutoAdjustment";
import Dashboard from "./home/Dashboard";

import ScaleLogChart from "./reports/ScaleLogChart";
import WeightLog from "./reports/WeightLog";
import EditScale from "./setting/EditScale";
import ScaleManager from "./setting/ScaleManager";
import UserSetting from "./ums/UserSetting";

import MessageCrud from "./messaging/MessageCrud";
import ActivityLog from "./reports/ActivityLog";


export default function PagesRoute({ user }) {

  return (
    //<Suspense fallback={<LayoutSplashScreen />}>
    <Suspense>
      <Switch>
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/Adjustment" component={Adjustment} />
        <Route path="/AutoAdjustment" component={AutoAdjustment} />
        <Route path="/AdjustmentReport" component={AdjustmentReport} />



        <Route path="/LogChart" component={ScaleLogChart} />
        <Route path="/UserSetting" component={UserSetting} />
        <Route path="/WeightLog" component={WeightLog} />
        <Route path="/setting/:_scaleId" component={EditScale} />
        <Route path="/setting" component={ScaleManager} />
        
        <Route path="/messaging" component={MessageCrud} />
        <Route path="/ActivityLog" component={ActivityLog} />
        



        <Redirect exact from="/" to="/dashboard" />
      </Switch>
    </Suspense>
  );
}
