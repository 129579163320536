import { Box, FormControl, Icon, IconButton, InputLabel, LinearProgress, MenuItem, Select, TextField } from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import React, { useEffect, useState } from 'react'
import { Modal, Col, Row, Button as BButton } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import ConfirmService from '../../layout/components/ConfirmService'
//import { updateReturn } from 'typescript'
import Fieldset from '../../partials/Fieldset'
import SimpleTable from '../../partials/SimpleTable'
import { post } from '../../services/base.service'
import { loaderActions } from '../../store/ducks/loader.duck'
import { snackbarActions } from '../../store/ducks/snackbar.duck'
import { dateFromNow, getDatePart, getTimePartMin } from '../../utils/helpers'


const Adjustment = () => {
    const [filter, setFilter] = useState({
        scaleId: null,
        today: false,
        from: dateFromNow(-5),
        to: null
    })
    const dispatch = useDispatch()
    const [list, setList] = useState([])
    const [showEdit, setShowEdit] = useState(null)
    const [loading, setLoading] = useState()

    const columns = [
        { title: 'Row', field: '#', width: 20 },
        { title: 'Date', template: ({ dateTime }) => getDatePart(dateTime), width: 100 },
        { title: 'Time', template: ({ dateTime }) => getTimePartMin(dateTime), width: 80 },
        { title: 'Bale Qty', field: 'baleQty', width: 50 },
        { title: 'Net Weight(lbs)', field: 'netWeight', width: 80 },
        {
            title: '', width: 20, template: (item) => (
                <>
                    <IconButton size="small" onClick={() => setShowEdit(item)}>
                        <Icon className="fa fa-pencil-alt" />
                    </IconButton>
                    &nbsp;&nbsp;
                    <IconButton size="small" onClick={() => deleteHandler(item)}>
                        <Icon className="fa fa-trash" />
                    </IconButton>
                </>
            )
        },
    ]

    const deleteHandler = (bale) => {
        ConfirmService.show('Are you sure?').then(isConfirmed => {
            if (isConfirmed) {
                dispatch(loaderActions.show());
                post('/api/adjustment/delete', bale).then(res => {
                    if (res.succeed) {
                        setFilter(p => ({ ...p }));
                        dispatch(snackbarActions.success('Deleted!'));
                    } else {
                        dispatch(snackbarActions.error(res.errorMessage));
                    }
                }).finally(() => dispatch(loaderActions.hide()));
            }
        })
    }

    useEffect(() => {
        if (!filter.scaleId) {
            return;
        }

        setLoading(true);
        post('/api/dashboard/GetBaleWeightList', filter).then(res => {
            setList(res.data)
        }).finally(() => setLoading(false));

    }, [filter])

    return (
        <>
            <Fieldset title="Adjustment">
                <Box p={1}>
                    <AdjustmentFilter filter={filter} onChange={setFilter} />
                </Box>
                {loading && <LinearProgress />}
                <SimpleTable
                    data={list}
                    columns={columns}
                />
            </Fieldset>
            {showEdit && <EditBale
                bale={showEdit}
                onConfirm={() => { setFilter(p => ({ ...p })); setShowEdit(null); }}
                onDismiss={() => { setShowEdit(null); }}
            />}
        </>
    )

}

export default Adjustment;

const AdjustmentFilter = ({ filter, onChange }) => {
    const [scaleList, setScaleList] = useState([]);
    const [loading, setLoading] = useState();

    const { control, errors, handleSubmit, setValue } = useForm({ defaultValues: { ...filter } });
    useEffect(() => {
        setLoading(true)
        post('/api/dashboard/GetScaleList', {}).then(res => {
            setScaleList(res.data);
            if(res.data.length > 0){
                setValue('scaleId',res.data[0].id);
                onChange(prev => ({...prev, scaleId : res.data[0].id}))
            }
        }).finally(() => setLoading(false));
    }, [])

    const submitHandler = (data) => {
        onChange({ ...data })
    }


    return (<>

        <form onSubmit={handleSubmit(submitHandler)}>

            <Row>
                <Col>
                    <FormControl variant="outlined" size="small" fullWidth error={Boolean(errors && errors.scaleId)}>
                        <InputLabel >Scale</InputLabel>
                        <Controller
                            name="scaleId"
                            control={control}
                            defaultValue={''}
                            rules={{ required: 'required' }}
                            as={

                                <Select
                                    label="Scale"
                                >
                                    {loading && <MenuItem>loading ...</MenuItem>}
                                    {scaleList.map(s => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>)}
                                </Select>
                            }
                        />
                    </FormControl>

                </Col>
                <Col>
                    <Controller
                        name="from"
                        control={control}
                        rules={{ required: 'required' }}
                        as={
                            <DateTimePicker
                                //onChange={(val) => setLocalFilter(prev => ({ ...prev, arrivalDateFrom: val }))}
                                //value={localFilter.arrivalDateFrom}
                                inputVariant="outlined"
                                autoOk
                                label="From Date Time"
                                size="small"
                                fullWidth
                            />}
                    />
                </Col>
                <Col>
                    <Controller
                        name="to"
                        control={control}
                        as={
                            <DateTimePicker
                                //onChange={(val) => setLocalFilter(prev => ({ ...prev, arrivalDateFrom: val }))}
                                //value={localFilter.arrivalDateFrom}
                                inputVariant="outlined"
                                autoOk
                                label="To Date Time"
                                size="small"
                                fullWidth
                            />}
                    />
                </Col>
                <Col sm={2}>
                    <BButton variant='primary' type='submit'><i className='fa fa-search' />&nbsp; Filter</BButton>
                </Col>
            </Row>
        </form>

    </>)
}

const EditBale = ({ bale, onDismiss, onConfirm }) => {

    const { control, errors, handleSubmit, getValues, trigger } = useForm({ defaultValues: { ...bale } });
    const dispatch = useDispatch()

    const save = (data) => {
        dispatch(loaderActions.show());
        post('/api/adjustment/edit', data ).then(res => {
            if (res.succeed) {
                dispatch(snackbarActions.success('Saved!'));
                onConfirm();
            } else {
                dispatch(snackbarActions.error(res.errorMessage));
            }
        }).finally(() => dispatch(loaderActions.hide()));
    }

    const confirmHandler = () => {
        trigger().then(res => {
            save(getValues())
        });
    }

    return (

        <Modal show={true} onHide={onDismiss}>
            <Modal.Header onDismiss>
                <Modal.Title>Edit & Add Adjustment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(save)}>
                    <Row>
                        <Col>
                            <Controller
                                name="netWeight"
                                control={control}
                                defaultValue={''}
                                rules={{ required: 'required' }}
                                as={
                                    <TextField
                                        autoFocus
                                        type="text"
                                        label="Net Weight"
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        error={Boolean(errors && errors.netWeight)}
                                    />
                                }
                            />
                        </Col>
                        <Col>
                            <Controller
                                name="baleQty"
                                control={control}
                                defaultValue={''}
                                rules={{ required: 'required' }}
                                as={
                                    <TextField
                                        autoFocus
                                        type="text"
                                        label="Bale Qty"
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        error={Boolean(errors && errors.baleQty)}
                                    />
                                }
                            />
                        </Col>
                    </Row>

                </form>

            </Modal.Body>
            <Modal.Footer>
                <BButton variant="secondary" onClick={onDismiss}>
                    Close
          </BButton>
                <BButton variant="primary" onClick={confirmHandler}>
                    Save Changes
          </BButton>
            </Modal.Footer>
        </Modal>
    )

}