import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Row, Button as BButton, Alert, Spinner, Table } from 'react-bootstrap';
import { post } from '../../services/base.service';
import { dynamicSort, getDatePart, getTimePart, getTimePartMin, sum } from '../../utils/helpers';
import Fieldset from '../../partials/Fieldset';

import "react-datepicker/dist/react-datepicker.css";
import { Box, FormControl, InputLabel, LinearProgress, MenuItem, Paper, Select, TextField } from '@material-ui/core';

import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { Controller, useForm } from 'react-hook-form';
import { DateTimePicker } from '@material-ui/pickers';
import SimpleTable from '../../partials/SimpleTable';


const AutoAdjustment = () => {

    const [loading, setLoading] = useState();
    const [list, setList] = useState();
    const [aggregated, setAggregated] = useState({});
    const [adjusted, setAdjusted] = useState([]);
    const dateFromNow = (i) => {
        var today = new Date();
        var d = new Date(today.setDate(today.getDate() + (i)));
        return d;
    }

    const [filter, setFilter] = useState(
        {
            scaleId: null,
            from: dateFromNow(-1),
            to: dateFromNow(0),
            zero: 100,
            scalingTime: 60
        }
    );

    useEffect(() => {
        if (!filter.scaleId) return;
        relaod();
    }, [filter])

    const relaod = () => {
        setAggregated({})
        setLoading(true);
        // post('/api/dashboard/GetBaleWeightList', filter).then(res => {
        //     setList(res.data)
        // }).finally(() => setLoading(false));

        post('/api/Adjustment/AutoAdjustReport', filter).then(res => {
            if (res.data) {
                let temp = {}
                temp.count = sum(res.data.map(x => x.baleCount));
                temp.sum = sum(res.data.map(x => x.total));
                setAggregated(temp);
                setAdjusted(res.data)
            }
        }).finally(() => setLoading(false));
    }

    const submitHandler = (e) => {
        e.preventDefault();
        relaod();
    }

    const inputChangeHandler = (name, value) => {
        setFilter(prev => {
            let t = { ...prev };
            t[name] = value;
            return t;
        })
    }

    const columns = [
        { title: 'Row', field: '#', width: 20 },
        { title: 'Date', template: ({ dateTime }) => getDatePart(dateTime), width: 100 },
        { title: 'Time', template: ({ dateTime }) => getTimePartMin(dateTime), width: 80 },
        { title: 'Bale Qty', field: 'baleQty', width: 50 },
        { title: 'Net Weight', field: 'netWeight', width: 80 },
    ]
    return (
        <>
        <Alert variant="info">
            This page works as a practical report and does not change computed bale count result!
        </Alert>
            <Row>
                <Col md={9}>
                    <Fieldset title='Fitter data' style={{ height: '220px' }}>
                        <AdjustmentForm filter={filter} onChange={setFilter} />
                    </Fieldset>
                </Col>
                <Col md={3}>
                    <Fieldset title='Adjusted result' style={{ height: '220px' }}>
                        <Table>
                            <tr>
                                <td>Bale Count:</td>
                                <td><strong>{aggregated && aggregated.count}</strong></td>
                            </tr>
                            <tr>
                                <td>Weight Sum:</td>
                                <td><strong>{aggregated && aggregated.sum}</strong></td>
                            </tr>
                        </Table>
                    </Fieldset>
                </Col>
            </Row>

            {loading && <LinearProgress />}
            <Fieldset>
                <ScaleChart filter={filter} adjustedData={adjusted} />
            </Fieldset>
            <Fieldset>
                <SimpleTable
                    data={adjusted}
                    columns={[
                        { title: 'Date', template: ({ date }) => getDatePart(date), width: 100 },
                        { title: 'Time', template: ({ date }) => getTimePartMin(date), width: 80 },
                        { title: 'Bale Qty', field: 'baleCount', width: 100 },
                        { title: 'Total', field: 'total', width: 100 },
                    ]}
                />
            </Fieldset>
        </>
    )
}

const AdjustmentForm = ({ filter, onChange }) => {
    const [scaleList, setScaleList] = useState([]);
    const [loading, setLoading] = useState();

    const { control, errors, handleSubmit, setValue } = useForm({ defaultValues: { ...filter } });
    useEffect(() => {
        setLoading(true)
        post('/api/dashboard/GetScaleList', {}).then(res => {
            setScaleList(res.data);
            if (res.data.length > 0) {
                setValue('scaleId', res.data[0].id);
                onChange(prev => ({ ...prev, scaleId: res.data[0].id }))
            }

        }).finally(() => setLoading(false));
    }, [])

    const submitHandler = (data) => {
        onChange({ ...data })
    }


    return (<>

        <form onSubmit={handleSubmit(submitHandler)}>

            <Row>
                <Col>
                    <FormControl variant="outlined" size="small" fullWidth error={Boolean(errors && errors.scaleId)}>
                        <InputLabel >Scale</InputLabel>
                        <Controller
                            name="scaleId"
                            control={control}
                            defaultValue={''}
                            rules={{ required: 'required' }}
                            as={

                                <Select
                                    label="Scale"
                                >
                                    {loading && <MenuItem>loading ...</MenuItem>}
                                    {scaleList.map(s => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>)}
                                </Select>
                            }
                        />
                    </FormControl>

                </Col>
                <Col>
                    <Controller
                        name="from"
                        control={control}
                        rules={{ required: 'required' }}
                        as={
                            <DateTimePicker
                                //onChange={(val) => setLocalFilter(prev => ({ ...prev, arrivalDateFrom: val }))}
                                //value={localFilter.arrivalDateFrom}
                                inputVariant="outlined"
                                autoOk
                                label="From Date Time"
                                size="small"
                                fullWidth
                            />}
                    />
                </Col>
                <Col>
                    <Controller
                        name="to"
                        control={control}
                        rules={{ required: 'required' }}
                        as={
                            <DateTimePicker
                                //onChange={(val) => setLocalFilter(prev => ({ ...prev, arrivalDateFrom: val }))}
                                //value={localFilter.arrivalDateFrom}
                                inputVariant="outlined"
                                autoOk
                                label="To Date Time"
                                size="small"
                                fullWidth
                            />}
                    />
                </Col>

            </Row>
            <Row className='mt-4'>
                <Col>
                    <Controller
                        name="zero"
                        control={control}
                        rules={{ required: 'required' }}
                        as={
                            <TextField
                                variant="outlined"
                                label="Zero threshold"
                                size="small"
                                fullWidth
                            />}
                    />
                </Col>
                <Col>
                    <Controller
                        name="scalingTime"
                        control={control}
                        rules={{ required: 'required' }}
                        as={
                            <TextField
                                variant="outlined"
                                label="Scaling Time (sec)"
                                size="small"
                                fullWidth
                            />}
                    />
                </Col>
                <Col>
                    <BButton variant='primary' type='submit'><i className='fa fa-search' />&nbsp; View</BButton>
                </Col>
            </Row>
        </form>

    </>)
}

const ScaleChart = ({ filter, adjustedData }) => {
    //const [data, setData] = useState([]);
    const [loading, setLoading] = useState();
    const canvasRef = useRef()
    const [chart, setChart] = useState();

    // useEffect(() => {
    //     if (!filter.scaleId)
    //         return;
    //     setLoading(true)


    //     post('/api/dashboard/getLog', filter).then(res => {
    //         setData(res.data);
    //         renderChart(res.data)
    //     }).finally(() => setLoading(false));
    // }, [filter])

    useEffect(() => {
        if (!filter.scaleId)
            return;
        setLoading(true)


        if (chart) {
            chart.destroy();
        }

        setLoading(true)


        post('/api/dashboard/getLog', filter).then(res => {
            renderChart(res.data, adjustedData)
        }).finally(() => setLoading(false));


    }, [adjustedData])

    const renderChart = (data, adjData) => {
        var d = data.sort(dynamicSort('dateTime')).map(x => ({ x: new Date(x.dateTime), y: x.weight }));
        var adj = adjData.sort(dynamicSort('date')).map(x => ({ x: new Date(x.date), y: x.total }));
        var config = {
            type: 'line',
            data: {
                datasets: [{
                    data: d,
                    label: 'Scale Changes',
                    borderColor: 'blue'
                }, {
                    data: adj,
                    label: 'Adjusted',
                    borderColor: 'red',
                    type: 'bar',
                    backgroundColor: 'rgb(255, 99, 132)',
                    //type: 'scatter'
                }]
            },
            options: {

                scales: {
                    x: {
                        type: 'time',
                        // time: {
                        //     unit: 'minute'
                        // }
                        time: {
                            unit: 'minute',
                            displayFormats: {
                                'millisecond': 'MMM dd, HH:mm',
                                'second': 'MMM dd, HH:mm',
                                'minute': 'MMM dd, HH:mm',
                                'hour': 'MMM dd, HH:mm',
                                'day': 'MMM dd',
                                'week': 'MMM dd',
                                'month': 'MMM dd',
                                'quarter': 'MMM dd',
                                'year': 'MMM dd',
                            }
                        }
                    }
                },
                plugins: {

                    title: {
                        display: false,
                        text: 'Scale recent Changes'
                    }
                }
            }
        }

        var c = new Chart(canvasRef.current, config);
        setChart(c);
    }


    return (
        <Box p={1} component={Paper}>
            <div>
                <canvas ref={canvasRef} height={100} />
            </div>
        </Box>
    )
}



export default AutoAdjustment;