//ali
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { loaderActions } from "../../store/ducks/loader.duck";


const GlobalLoader = () => {

    const isShowing = useSelector(state => state.loader.isShowing);

    let content = null;
    if (isShowing) {
        content = (
            <div id="plzw">
                <div className="plzw-content">
                    <div className="plzw-loader">
                        {/* <img src="/media/logos/logo.png" alt="logo" /> */}
                        <CircularProgress className="kt-splash-screen__spinner" />
                        {/* <i className="fa fa-spinner fa-spin fa-8x"></i> */}
                    </div>
                </div>
            </div>)
    }

    return (
        <>
            {content}
        </>
    );
}


export default GlobalLoader;

export function LayoutSplashScreen({ visible = false }) {
    const dispatch = useDispatch();
  
    useEffect(() => {
      if (!visible) {
        return;
      }
  
      const id = Math.random();
  
      dispatch(loaderActions.show());
  
      return () => {
        dispatch(loaderActions.hide());
      };
    }, [visible, dispatch]);
  
    return null;
  }
