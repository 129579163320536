import React from 'react'


const Version = () =>{


    return (
        <>
            <h1>1.0.7</h1>
        </>
    )
}

export default Version;