import { format } from "date-fns";
import store from "../store/store";



export function getDatePart(dateStr) {
    var date = new Date(dateStr);
    if (!date) return "";
    return format(date, "dd/MM/yyyy");
}

export function getDateTimeFormated(dateStr) {
    var date = new Date(dateStr);
    if (!date) return "";
    return format(date, "dd/MM/yyyy") +" "+format(date, "HH:mm");
}

export function getTimePartMin(dateStr) {
    var date = new Date(dateStr);
    if (!date) return "";
    return format(date, "HH:mm");
}
export function getTimePart(dateStr) {
    var date = new Date(dateStr);
    if (!date) return "";
    return format(date, "HH:mm:ss");
}

export const dateFromNow = (i) => {
    var val = new Date();
    var today = new Date(val.getFullYear(), val.getMonth(), val.getDate());

    return new Date(today.setDate(today.getDate() + (i)))
}

export function dynamicSort(property, dir) {
    var sortOrder = 1;
    if (dir === "desc") {
        sortOrder = -1;
    }
    return function (a, b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export function sum(arr){
    return arr.reduce((a, b) => a + b, 0)
}

export function encodeObject(params) {
    var token = store.getState().auth.authToken;
    var query = [`t=${token}`];
    for (let key in params) {
      if (params[key] == null)
        continue;
      if (Array.isArray(params[key])) {
        for (let j = 0; j < params[key].length; j++) {
          let val = encodeURIComponent(key) + "=" + encodeURIComponent(params[key][j]);
          query.push(val);
        }
      } else if (params[key] instanceof Date) {
        let val = encodeURIComponent(key) + "=" + encodeURIComponent(params[key].toISOString());
        query.push(val);
      } else {
        let val = encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
        query.push(val);
      }
    }
    return query.join('&');
  }

