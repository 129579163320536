import React, { Component, useEffect } from "react";
import * as auth from "../../store/ducks/auth.duck";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../layout/components/GlobalLoader";

const Logout = () => {

  const { user } = useSelector(store => store.auth);
  let isAuthorized = user != null;
  useSelector(store => store.auth)

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(auth.actions.logout())
  }, [])



  return isAuthorized ? <LayoutSplashScreen /> : <Redirect to="/auth" />;

}

export default Logout
