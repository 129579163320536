import { Box, Paper } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { post } from '../../services/base.service';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { dateFromNow, dynamicSort } from '../../utils/helpers';


const ScaleChart = ({ scale }) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState();
    const chart = useRef()

    useEffect(() => {
        setLoading(true)
        post('/api/dashboard/GetTodayLog', { id: scale.id }).then(res => {
            setData(res.data);
            renderChart(res.data)
        }).finally(() => setLoading(false));
    }, [scale])

    const renderChart = (items) => {
        var d = items.sort(dynamicSort('dateTime')).map(x => ({ x: new Date(x.dateTime), y: x.weight }));

        var config = {
            type: 'line',
            data: {
                datasets: [{
                    data: d,
                    //label: scale.name,
                    borderColor: 'blue'
                }]
            },
            options: {
                scales: {
                    y: {
                        suggestedMax: 1000
                    },
                    x: {
                        type: 'time',
                        // time: {
                        //     unit: 'minute'
                        // }
                        time: {
                            unit: 'minute',
                            displayFormats: {
                                'millisecond': 'MMM dd, HH:mm',
                                'second': 'MMM dd, HH:mm',
                                'minute': 'MMM dd, HH:mm',
                                'hour': 'MMM dd, HH:mm',
                                'day': 'MMM dd',
                                'week': 'MMM dd',
                                'month': 'MMM dd',
                                'quarter': 'MMM dd',
                                'year': 'MMM dd',
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: 'Scale recent Changes'
                    }
                }
            }
        }

        new Chart(chart.current, config)
    }


    return (
        <Box p={1} component={Paper}>
            <div>
                <canvas ref={chart} height={100} />
            </div>
        </Box>
    )
}

export default ScaleChart