import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Row, Button as BButton, Jumbotron, Spinner, Table } from 'react-bootstrap';
import { post } from '../../services/base.service';
import { dynamicSort, getDatePart, getTimePart, getTimePartMin } from '../../utils/helpers';
import Fieldset from '../../partials/Fieldset';

import "react-datepicker/dist/react-datepicker.css";
import { Box, FormControl, InputLabel, LinearProgress, MenuItem, Paper, Select } from '@material-ui/core';

import { WeigthLogFilter } from './WeightLog';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';

const ScaleLogChart = () => {

    const [loading, setLoading] = useState();
    const [list, setList] = useState();
    const [aggregated, setAggregated] = useState({});
    const dateFromNow = (i) => {
        var today = new Date();
        var d = new Date(today.setDate(today.getDate() + (i)));
        return d;
    }
    const [filter, setFilter] = useState(
        {
            scaleId: null,
            from: dateFromNow(-1),
            to: null,
            today: false
        }
    );

    useEffect(() => {
        if (!filter.scaleId) return;
        relaod();
    }, [filter])

    const relaod = () => {
        setAggregated({})
        setLoading(true);
        // post('/api/dashboard/GetBaleWeightList', filter).then(res => {
        //     setList(res.data)
        // }).finally(() => setLoading(false));

        post('/api/dashboard/GetAggregatedData', filter).then(res => {
            setAggregated(res.data);
        }).finally(() => setLoading(false));
    }

    const submitHandler = (e) => {
        e.preventDefault();
        relaod();
    }

    const inputChangeHandler = (name, value) => {
        setFilter(prev => {
            let t = { ...prev };
            t[name] = value;
            return t;
        })
    }

    const columns = [
        { title: 'Row', field: '#', width: 20 },
        { title: 'Date', template: ({ dateTime }) => getDatePart(dateTime), width: 100 },
        { title: 'Time', template: ({ dateTime }) => getTimePartMin(dateTime), width: 80 },
        { title: 'Bale Qty', field: 'baleQty', width: 50 },
        { title: 'Net Weight', field: 'netWeight', width: 80 },
    ]
    return (
        <>
            <Row>
                <Col md={9}>
                    <Fieldset title='Fitter data' style={{ height: '235px' }}>
                        <WeigthLogFilter filter={filter} onChange={setFilter} />
                    </Fieldset>
                </Col>
                <Col md={3}>
                    <Fieldset title='Aggregated result' style={{ height: '235px' }}>
                        <Table>
                            <tr>
                                <td>Bale Count:</td>
                                <td><strong>{aggregated && aggregated.count}</strong></td>
                            </tr>
                            <tr>
                                <td>Weight Sum:</td>
                                <td><strong>{aggregated && aggregated.sum}</strong></td>
                            </tr>
                        </Table>
                    </Fieldset>
                </Col>
            </Row>

            {loading && <LinearProgress />}
            <Fieldset>
                <Row>
                    <Col>
                        <ScaleChart filter={filter} />


                    </Col>
                </Row>
            </Fieldset>

        </>
    )
}

const ScaleChart = ({ filter }) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState();
    const canvasRef = useRef()
    const [chart, setChart] = useState();

    useEffect(() => {
        if (!filter.scaleId)
            return;
        setLoading(true)

        if (chart) {
            chart.destroy();
        }
        post('/api/dashboard/getLog', filter).then(res => {
            setData(res.data);
            renderChart(res.data)
        }).finally(() => setLoading(false));
    }, [filter])

    const renderChart = (items) => {
        var d = items.sort(dynamicSort('dateTime')).map(x => ({ x: new Date(x.dateTime), y: x.weight }));

        var config = {
            type: 'line',
            data: {
                datasets: [{
                    data: d,
                    //label: scale.name,
                    borderColor: 'blue'
                }]
            },
            options: {
                scales: {
                    x: {
                        type: 'time',
                        // time: {
                        //     unit: 'minute'
                        // }
                        time: {
                            unit: 'minute',
                            displayFormats: {
                                'millisecond': 'MMM dd, HH:mm',
                                'second': 'MMM dd, HH:mm',
                                'minute': 'MMM dd, HH:mm',
                                'hour': 'MMM dd, HH:mm',
                                'day': 'MMM dd',
                                'week': 'MMM dd',
                                'month': 'MMM dd',
                                'quarter': 'MMM dd',
                                'year': 'MMM dd',
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: false,
                        text: 'Scale recent Changes'
                    }
                }
            }
        }

        var c = new Chart(canvasRef.current, config);
        setChart(c);
    }


    return (
        <Box p={1} component={Paper}>
            <div>
                <canvas ref={canvasRef} height={100} />
            </div>
        </Box>
    )
}


export default ScaleLogChart;