import React from "react";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // typography: {
    //   fontFamily: ["Poppins"].join(",")
    // },

    palette: {
      contrastThreshold: 2,
      text: {
        primary: "#000",
      },
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#5d78ff"
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: "#0abb87",
        // dark: will be calculated from palette.primary.main,
        contrastText: "#ffffff"
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: "#fd397a"
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      white: {
        main: "#ffffff",
        contrastText: "#000000"
      }
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1
      }
    },
    overrides: {
      MuiMenu: {
        paper: {
          backgroundColor: 'rgb(251, 251, 227)'
        }
      },
      MuiTableCell: {
        head: {
          height: "45px"
        },
        stickyHeader: {
          backgroundColor: "none",
          background: "linear-gradient(0deg, rgba(236,236,236,1) 0%, rgba(250,250,250) 60%, rgba(242,242,242,1) 100%)",
        },
        sizeSmall: {
          padding: "3px 5px 3px 5px",
          "&:last-child": {
            paddingRight: "10px"
          },
          overflow: "hidden"
        }
      }, MuiTablePagination: {
        spacer: {
          flex: "1 1 0%"
        }
      },
      MuiTooltip: {
        tooltip: {
          fontSize: ".9rem"
        }
      },
      MuiDrawer: {
        paper: {
          overflowY: 'visible'
        }
      },
      MuiButtonBase: {
        root: {
          color: '#000'
        }
      },
      MuiButton: {
        root: {
          fontSize: '1rem',
          textTransform: 'none'
        }
      }
    }
  }
);

export default function ThemeProvider(props) {
  const { children } = props;

  return <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {children}
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>;
}
