import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Button as BButton, Jumbotron, Spinner, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import SimpleTable from '../../partials/SimpleTable';
import { post, baseUrl } from '../../services/base.service';
import { encodeObject, getDatePart, getTimePart, getTimePartMin } from '../../utils/helpers';
import DatePicker from "react-datepicker";
import Fieldset from '../../partials/Fieldset';

import "react-datepicker/dist/react-datepicker.css";
import { FormControl, InputLabel, LinearProgress, MenuItem, Select } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { DateTimePicker } from '@material-ui/pickers';

const WeightLog = () => {

    const [loading, setLoading] = useState();
    const [list, setList] = useState();
    const [aggregated, setAggregated] = useState({});
    const dateFromNow = (i) => {
        var today = new Date();
        var d = new Date(today.setDate(today.getDate() + (i)));
        return d;
    }
    const [filter, setFilter] = useState({
        scaleId: null,
        from: dateFromNow(-1),
        to: null,
        today: false
    });

    useEffect(() => {
        if (!filter.scaleId) return;
        relaod();
    }, [filter])

    const relaod = () => {
        setAggregated({})
        setLoading(true);
        post('/api/dashboard/GetBaleWeightList', filter).then(res => {
            setList(res.data)
        }).finally(() => setLoading(false));

        post('/api/dashboard/GetAggregatedData', filter).then(res => {
            setAggregated(res.data);
        });
    }

    const submitHandler = (e) => {
        e.preventDefault();
        relaod();
    }

    const inputChangeHandler = (name, value) => {
        setFilter(prev => {
            let t = { ...prev };
            t[name] = value;
            return t;
        })
    }

    const columns = [
        { title: 'Row', field: '#', width: 20 },
        { title: 'Date', template: ({ dateTime }) => getDatePart(dateTime), width: 100 },
        { title: 'Time', template: ({ dateTime }) => getTimePartMin(dateTime), width: 80 },
        { title: 'Bale Qty', field: 'baleQty', width: 50 },
        { title: 'Net Weight', field: 'netWeight', width: 80 },
    ]
    return (
        <>
            <Row>
                <Col md={9}>
                    <Fieldset title='Fitter data' style={{ height: '200px' }}>
                        <WeigthLogFilter filter={filter} onChange={setFilter} />
                    </Fieldset>
                </Col>
                <Col md={3}>
                    <Fieldset title='Aggregated result' style={{ height: '200px' }}>
                        <Table>
                            <tr>
                                <td>Bale Count:</td>
                                <td><strong>{aggregated && aggregated.count}</strong></td>
                            </tr>
                            <tr>
                                <td>Weight Sum:</td>
                                <td><strong>{aggregated && aggregated.sum}</strong></td>
                            </tr>
                        </Table>
                    </Fieldset>
                </Col>
            </Row>

            {loading && <LinearProgress />}
            <Fieldset>
                <Row>
                    <Col>
                        <SimpleTable
                            columns={columns}
                            data={list}
                        />
                    </Col>
                </Row>
            </Fieldset>

        </>
    )
}

export const WeigthLogFilter = ({ filter, onChange }) => {
    const [scaleList, setScaleList] = useState([]);
    const [loading, setLoading] = useState();

    const { control, errors, handleSubmit, setValue, getValues, watch } = useForm({ defaultValues: { ...filter } });
    useEffect(() => {
        setLoading(true)
        post('/api/dashboard/GetScaleList', {}).then(res => {
            setScaleList(res.data);
            if (res.data.length > 0) {
                setValue('scaleId', res.data[0].id);
                onChange(prev => ({ ...prev, scaleId: res.data[0].id }))
            }

        }).finally(() => setLoading(false));
    }, [])

    const submitHandler = (data) => {
        onChange({ ...data })
    }

    useEffect(() =>{
        var vals = getValues();
        if(vals.to)
            onChange(vals)
    },[watch('to')])

    const exportHandler = (e) => {
        e.preventDefault();
        var f = getValues();
        var searchParam = encodeObject(f);
        window.location.href = baseUrl + '/api/dashboard/GetBaleWeightCSV?' + searchParam;
        return false;
    }


    return (<>

        <form onSubmit={handleSubmit(submitHandler)}>

            <Row>
                <Col>
                    <FormControl variant="outlined" size="small" fullWidth error={Boolean(errors && errors.scaleId)}>
                        <InputLabel >Scale</InputLabel>
                        <Controller
                            name="scaleId"
                            control={control}
                            defaultValue={''}
                            rules={{ required: 'required' }}
                            as={

                                <Select
                                    label="Scale"
                                >
                                    {loading && <MenuItem>loading ...</MenuItem>}
                                    {scaleList.map(s => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>)}
                                </Select>
                            }
                        />
                    </FormControl>

                </Col>
                <Col>
                    <Controller
                        name="from"
                        control={control}
                        rules={{ required: 'required' }}
                        as={
                            <DateTimePicker
                                //onChange={(val) => setLocalFilter(prev => ({ ...prev, arrivalDateFrom: val }))}
                                //value={localFilter.arrivalDateFrom}
                                inputVariant="outlined"
                                autoOk
                                label="From Date Time"
                                size="small"
                                fullWidth
                            />}
                    />
                </Col>
                <Col>
                    <Controller
                        name="to"
                        control={control}
                        as={
                            <DateTimePicker
                                //onChange={(val) => setLocalFilter(prev => ({ ...prev, arrivalDateFrom: val }))}
                                //value={localFilter.arrivalDateFrom}
                                inputVariant="outlined"
                                autoOk
                                label="To Date Time"
                                size="small"
                                fullWidth
                            />}
                    />
                </Col>

            </Row>
            <Row className='mt-4'>
                <Col sm={4}>
                    <BButton variant='primary' type='submit'><i className='fa fa-search' />&nbsp; Filter</BButton>&nbsp;
                    <BButton variant='secondary' onClick={exportHandler}><i className='fa fa-file-excel' />&nbsp; Excel</BButton>
                </Col>
            </Row>
        </form>

    </>)
}

export default WeightLog;