//ali
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";
import { Snackbar, Slide, LinearProgress, Button } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { snackbarActions } from "../../store/ducks/snackbar.duck";
import { AlertTitle } from "@material-ui/lab";

const GlobalSnackbar = () => {
    const messages = useSelector(state => state.snackbar);
    const dispatch = useDispatch()

    const handleClose = (msg) => {
        dispatch(snackbarActions.remove(msg))
    }

    return (
        <>
            {messages.map((msg, i) =>
                <SingleSnackbar key={i} index={i} type={msg.type} message={msg.message} onClose={handleClose} />
            )}
        </>
    );
}

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

const SingleSnackbar = ({ type, message, onClose, index }) => {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        onClose(message);
    }

    const [completed, setCompleted] = useState(0);
    useEffect(() => {

        if (message == null)
            return;

        let duration = 8000;
        if (type == 'success')
            duration = 2000;
        if (type == 'info')
            duration = 3000;
        if (type == 'warning')
            duration = 5000;

        function progress() {
            setCompleted((oldCompleted) => {
                if (oldCompleted === 100) {
                    clearInterval(timer);
                    handleClose();
                }
                const diff = (200 / duration) * 100;
                return Math.min(oldCompleted + diff, 100);
            });
        }


        const timer = setInterval(progress, 200);
        return () => {
            clearInterval(timer);
            setCompleted(0);
        };
    }, []);

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "left"
            }}
            TransitionComponent={SlideTransition}
            open={true}
            //autoHideDuration={duration}
            onClose={handleClose}
            className={'snackbar-' + index}

        >
            <div>
                <Alert elevation={6} variant="filled" severity={type}
                    // action={
                    //     <Button variant='contained' size="small" onClick={handleClose}>
                    //         Close
                    //     </Button>
                    // }
                    onClose={handleClose}
                >
                    <div className='kt-font-lg'>
                        {message != null ? message : ""}
                    </div>
                </Alert>
                <LinearProgress variant="determinate" value={completed} style={{ marginTop: '-4px' }} />
            </div>
        </Snackbar>
    )
}

export default GlobalSnackbar;
