import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TextField, InputAdornment, IconButton, FormControlLabel, Checkbox, LinearProgress, CircularProgress } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import { Row, Col, Button as BButton } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Alert, AlertTitle } from "@material-ui/lab";
import { post } from "../../services/base.service";

function Login(props) {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showForgetPass, setShowForgetPass] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    width: "100px",
    height: "50px"
  });

  const { register, control, handleSubmit, errors, setValue } = useForm();

  const dispatch = useDispatch();

  const enableLoading = () => {
    setLoading(true);
    //setLoadingButtonStyle(prev => ({ ...prev, width: "125px" }));
  };

  const disableLoading = () => {
    setLoading(false);
    //setLoadingButtonStyle(prev => ({ ...prev, width: "100px" }));
  };

  const handleClickShowPassword = () => {
    setShowPass(prev => !prev);
  }




  const onSubmit = (values) => {
    enableLoading();
    post('/api/auth/login', values).then(res => {
      disableLoading();
      if (res.succeed) {
        dispatch(auth.actions.login(res.data));

      } else {
        setStatus(res.errorMessage);
      }
    }).catch((err) => {
      disableLoading();
      setStatus("an error has been accurred");
    });
  };

  return (
    <>


      <div className="login-head">
        <h3>Login</h3>
        <p>Welcome, please login to your account.</p>
      </div>

      <form
        noValidate={true}
        autoComplete="off"
        className="kt-form form-container"
        onSubmit={handleSubmit(onSubmit)}
      >

        {status &&
          <Alert severity="error">
            {status}
          </Alert>
        }

        <Row>
          <Col >
            <Controller
              name="username"
              control={control}
              defaultValue={''}
              rules={{ required: 'required' }}
              as={
                <TextField
                  autoFocus
                  type="text"
                  label="Username"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  fullWidth
                  //helperText={errors.username}
                  error={Boolean(errors && errors.username)}
                />
              }
            />

          </Col>
        </Row>
        <Row>
          <Col >
            <Controller
              name="password"
              control={control}
              defaultValue={''}
              rules={{ required: 'required' }}
              as={
                <TextField
                  type={showPass ? 'text' : 'password'}
                  margin="normal"
                  label="Password"
                  variant="outlined"
                  fullWidth
                  size="small"
                  //helperText={errors.password}
                  error={Boolean(errors && errors.password)}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPass ? <i className="fa fa-eye-slash" /> : <i className="fa fa-eye" />}
                        </IconButton>
                      </InputAdornment>
                  }}
                />
              }
            />

          </Col>

        </Row>
        <div className="d-flex justify-content-md-center">
          <FormControlLabel
            control={
              <Checkbox name="rememberMe" inputRef={register} />
            }
            label="Remember me"
          />
        </div>


        <div className="d-flex justify-content-md-center">
          <BButton
            size="sm"
            variant='primary'
            disabled={loading}
            style={loadingButtonStyle}
            type="submit"
          >
            Login {loading && <CircularProgress color='secondary' size={15} />}
          </BButton>
        </div>



      </form>
      {/* 
                    <div className="kt-login__divider">
                        <div className="kt-divider">
                            <span />
                            <span>OR</span>
                            <span />
                        </div>
                    </div>
                    <div>
                        <a onClick={() => setShowForgetPass(true)}>
                            <img src="/media/icons/forget-pass.png" style={{ width: '50px' }} />
                            <span>
                                I forgot my username/password, send me an Email.
                            </span>
                        </a>
                    </div> */}



    </>
  );
}

export default Login;
