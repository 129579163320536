import { LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Col, Row, Table, Alert, Button, Spinner } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import Fieldset from '../../partials/Fieldset';
import SimpleTable from '../../partials/SimpleTable';
import { post } from '../../services/base.service';
import { getTimePart, getDatePart, getTimePartMin } from '../../utils/helpers'
import ScaleChart from './_ScaleChart';

const Dashboard = () => {
    const [selected, setSelected] = useState();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [timeToRefresh, setTimeToRefresh] = useState(0);

    // useEffect(() => {
    //     reload();
    // }, [])

    const reload = () => {
        setLoading(true)
        post('/api/dashboard/GetAggregatedData', {}).then(res => {
            if (res.succeed) {
                setData(res.data);
                //if (!scaleId) setScaleId(res.data.scale.id)
                //setTimeToRefresh(2 * 60);
            } else {

            }
        }).finally(() => setLoading(false));
    }


    useEffect(() => {
        reload()
    }, [])



    // useEffect(() => {
    //     if (!loading)
    //         setTimeToRefresh(2 * 60)
    // }, [loading])

    // useEffect(() => {
    //     function progress() {

    //         setTimeToRefresh((old) => {
    //             if (old == -1) { // in progress
    //                 return old;
    //             }
    //             else if (old === 0) {
    //                 reload();
    //                 return -1;
    //             } else {
    //                 return old - 1;
    //             }
    //         });
    //     }
    //     const timer = setInterval(progress, 1000);
    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, [])


    if (!data) {
        return <> loading ...</>;
    }

    return (
        <>
            <Row>
                <Col sm={6} md={3}>
                    <DashboardBox title="Today Total Bales" color="darkblue">
                        {<h1>{data.count}</h1>}
                    </DashboardBox>
                </Col>
                <Col sm={6} md={3}>
                    <DashboardBox title="Today Total Weight(lbs)" color="blue">
                        {<h1>{data.sum}</h1>}

                    </DashboardBox>
                </Col>
                <Col sm={6} md={3}>
                    <DashboardBox title="Line Status Error / Normal" color="green">
                        <h1>{data.status}</h1>
                    </DashboardBox>
                </Col>
                <Col sm={6} md={3}>
                    <DashboardBox title="Last Update" color="orange">
                        <h2>{getDatePart(data.lastReadDate)}</h2>
                        <h2>{getTimePart(data.lastReadDate)}</h2>
                    </DashboardBox>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col md={3}>
                    <ScaleList onSelect={setSelected} />
                </Col>
                <Col md={9}>
                    {selected && <ScaleChart scale={selected} /> }
                </Col>
            </Row>


        </>
    )
}


const DashboardBox = ({ title, children, color }) => {
    return (
        <div className={' dash-stat-box ' + color}>
            <div className='ds-title'>{title}</div>
            <div className='ds-body'>{children}</div>
        </div>
    )
}

const ScaleList = ({ onSelect }) => {

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState()
    const lantN = 5;
    useEffect(() => {
        setLoading(true)
        post('/api/dashboard/GetScaleList', {}).then(res => {
            setList(res.data);
            onSelect(res.data[0])
        }).finally(() => setLoading(false));
    }, [])


    return (<>
        <Fieldset title="Scale List">
            {loading && <LinearProgress />}
            <SimpleTable
                columns={[
                    { title: 'Code', field: 'code', width: 30 },
                    { title: 'Name', field: 'name', width: 100 },
                    { template: (item) => <Button size="sm" onClick={() => onSelect(item)}>Chart</Button>, width: 20 },
                ]}
                data={list}
            />
        </Fieldset>
    </>)


}



export default Dashboard;