
export const actionTypes = {
  success: "[successMessage] Action",
  error: "[errorMessage] Action",
  info: "[infoMessage] Action",
  warning: "[warningMessage] Action",
  clear: "[clearMessage] Action",
  remove: "[removeMessage] Action",
};

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.success: {
      return [...state, { type: "success", message: action.message }];
    }
    case actionTypes.error: {
      return [...state, { type: "error", message: action.message }];
    }
    case actionTypes.warning: {
      return [...state, { type: "warning", message: action.message}];
    }
    case actionTypes.info: {
      return [...state, { type: "info", message: action.message }];
    }
    case actionTypes.remove: {
      return state.filter(x => x.message != action.message);
    }
    case actionTypes.clear: {
      return [];
    }
    default:
      return state;
  }
}

export const snackbarActions = {
  success: (message) => ({ type: actionTypes.success, message }),
  error: (message) => ({ type: actionTypes.error, message }),
  info: (message) => ({ type: actionTypes.info, message }),
  warning: (message) => ({ type: actionTypes.warning, message }),
  remove: (message) => ({ type: actionTypes.remove, message }),
  clear: () => ({ type: actionTypes.clear }),
};
