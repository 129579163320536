import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Alert, Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { post } from '../../services/base.service';
import Fieldset from '../../partials/Fieldset'

const EditScale = () => {

    const { _scaleId } = useParams();
    const [scale, setScale] = useState();
    const [loading, setLoading] = useState();
    const [message, setMessage] = useState();
    useEffect(() => {
        if (!_scaleId) return;
        post('/api/Scale/GetScale', { id: _scaleId }).then(res => {
            setScale(res.data)
        })

    }, [_scaleId]);

    const submitHandler = () => {
        setLoading(true);
        post('/api/Scale/updateScale', scale).then(res => {
            if (res.succeed) {
                setMessage({ type: 'success', text: 'Saved Successfully.' });
            } else {
                setMessage({ type: 'error', text: res.errorMessage });
            }
        }).finally(() => setLoading(false));

    }

    const inputChangeHandler = (name, value) => {
        setScale(prev => {
            let t = { ...prev };
            t[name] = value;
            return t;
        })
    }

    if (!scale)
        return <>Loading ...</>;

    return (
        <>
            {!loading && message && <Alert variant={message.type}>
                {message.text}
            </Alert>}
            <Row>
                <Col>
                    <Fieldset title="Scale">
                        <Row>
                            <Col><Input label='Scale Name' name='name' handler={inputChangeHandler} value={scale.name} /></Col>
                            <Col><Input label='Scale Code' name='code' handler={inputChangeHandler} value={scale.code} /></Col>
                        </Row>
                    </Fieldset>
                </Col>
                <Col>
                    <Fieldset title="Bale">
                        <Row>
                            <Col><Input label='Bale Min Weight(lbs)' name='baleMinWeight' handler={inputChangeHandler} value={scale.baleMinWeight} /></Col>
                            <Col><Input label='Bale Max Weight(lbs)' name='baleMaxWeight' handler={inputChangeHandler} value={scale.baleMaxWeight} /></Col>
                        </Row>
                    </Fieldset>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Fieldset title="Scale Port Setting">
                        <Row>
                            <Col><Input label='Port No' name='portNumber' handler={inputChangeHandler} value={scale.portNumber} /></Col>
                            <Col><Input label='Baud Rate' name='baudRate' handler={inputChangeHandler} value={scale.baudRate} /></Col>
                        </Row>
                        <Row>
                            <Col><Input label='Data bits' name='dataBits' handler={inputChangeHandler} value={scale.dataBits} /></Col>
                            <Col><Input label='Parity' name='parity' handler={inputChangeHandler} value={scale.parity} /></Col>
                        </Row>
                        <Row>
                            <Col><Input label='Stop bits' name='stopBits' handler={inputChangeHandler} value={scale.stopBits} /></Col>
                            <Col></Col>
                        </Row>
                    </Fieldset>
                </Col>
                <Col>
                    {/* <Fieldset title="Average time of Scaling">
                        <Row>
                            <Col><Input label='Port No' name='baleMinWeight' handler={inputChangeHandler} value={scale.baleMinWeight} /></Col>
                            <Col></Col>
                        </Row>
                    </Fieldset> */}
                </Col>
            </Row>
            <div>
                <div className="d-flex justify-content-center">
                    <Button variant="primary" size="lg" onClick={submitHandler} disabled={loading}>Save</Button>
                    &nbsp;
                    <Link to={`/dashboard/${_scaleId}`}>
                        <Button variant="secondary" size="lg">Exit</Button>
                    </Link>
                </div>
            </div>

        </>
    )
}

export default EditScale;


const Input = ({ label, name, handler, ...rest }) => {

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Form.Control onChange={(e) => handler(name, e.target.value)} {...rest} />
        </Form.Group>)
}