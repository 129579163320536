import React from 'react'
import GlobalSnackbar from './components/GlobalSnackbar';
import GlobalLoader from './components/GlobalLoader';
import Header from './_Header';


const Layout = ({ children }) => {

    return (
        <>
            <Header />
            <div className='content'>
                {children}
            </div>
            <GlobalLoader/>
            <GlobalSnackbar/>
        </>
    )

}
export default Layout;