import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Button as BButton } from 'react-bootstrap';
import SimpleTable from '../../partials/SimpleTable';
import { post, baseUrl } from '../../services/base.service';
import { encodeObject, getDatePart, getTimePart, getTimePartMin } from '../../utils/helpers';
import Fieldset from '../../partials/Fieldset';
import { LinearProgress } from '@material-ui/core';



const ActivityLog = () => {

    const [loading, setLoading] = useState();
    const [list, setList] = useState();

    useEffect(() => {
        reload();
    }, [])

    const reload = () => {
        setLoading(true);
        post('/api/dashboard/GetActivityLog', {}).then(res => {
            setList(res.data)
        }).finally(() => setLoading(false));
    }

    

    const columns = [
        { title: 'Row', field: '#', width: 20 },
        { title: 'Date', template: ({ dateTime }) => getDatePart(dateTime), width: 80 },
        { title: 'Time', template: ({ dateTime }) => getTimePartMin(dateTime), width: 50 },
        { title: 'User', field: 'userName', width: 100 },
        { title: 'Action', field: 'action', width: 250 },
    ]
    return (
        <>
            {loading && <LinearProgress />}
            <Fieldset>
                <Row>
                    <Col>
                        <SimpleTable
                            columns={columns}
                            data={list}
                        />
                    </Col>
                </Row>
            </Fieldset>

        </>
    )
}


export default ActivityLog;