import React, { Component } from 'react';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
//import { Route } from 'react-router';

import './custom.scss'
import "@fortawesome/fontawesome-free/css/all.min.css";
import { LayoutSplashScreen } from './layout/components/GlobalLoader';
import Layout from './layout/Layout';
import ThemeProvider from './layout/materialUIThemeProvider/ThemeProvider';
import AuthPage from './pages/auth/AuthPage';
import LogoutPage from "./pages/auth/Logout";
import PagesRoute from './pages/PagesRoute';
import Version from './pages/home/Version';

export default function App({ store, persistor, basename }) {

  return (
    /* Provide Redux store */
    <Provider store={store} loading={<LayoutSplashScreen />}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <ThemeProvider>
              {/* Render routes with provided `Layout`. */}
              <Routes />
            </ThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}

const Routes = withRouter(({ history }) => {

  const { user, expireDate } = useSelector(store => store.auth);
  let isAuthorized = user != null && parseInt(expireDate) > new Date().getTime()
  return (
    <Switch>

      <Route path="/v" exact component={Version} />


      {!isAuthorized ? (
        /* Render auth page when user at `/auth` and not authorized. */
        <AuthPage />
      ) : (
          /* Otherwise redirect to root page (`/`) to={userLastLocation}*/
          <Redirect from="/auth" to='/' />
        )}

      {/* <Route path="/error" component={ErrorsPage} /> */}
      <Route path="/logout" component={LogoutPage} />



      {!isAuthorized ? (
        /* Redirect to `/auth` when user is not authorized */
        <Redirect to="/auth/login" />
      ) : (
          <Layout>
            <PagesRoute />
          </Layout>
        )}
    </Switch>
  )

})
