import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import * as loader from "./ducks/loader.duck";
import * as snackbar from "./ducks/snackbar.duck";
//import storage from "redux-persist/lib/storage";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  loader: loader.reducer,
  snackbar: snackbar.reducer,
});


